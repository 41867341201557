li{
  list-style-type: none;
}

body{
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: blue;
  color: white;
}

button{
  background-color: aqua;
 border: none; 
 border-radius: 5px;
 margin: 5px;
}

ul{
  display: flex;
  flex-direction: column;
}

li{
  border: 1px solid black;
  margin: 2px;
  align-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.cartli{
  background-color: lightcoral;
}

.catli{
  background-color: green;
}